@font-face{
    font-family:'YouSheBiaoTiHei';
    src:url(../../assets//fonts/YouSheBiaoTiHei-2.ttf)
}
@keyframes maskMove{
    from{
        transform:translateX(0px) translateY(0px)
    }
    to{
        transform:translateX(0px) translateY(-500px);
    }
}
@keyframes maskNarrow {
    from{
        transform: scaleY(1);
    }
    to{
        transform: scaleY(0);
    }
}
.mask{
    position: absolute;
    height:100px;
    width: 100%;
    top:0px;
    background-color: #040A20;
    z-index: 5;
}
.LetterText_empty{
    position: absolute;
    width:100%;
    height:100vh;
    z-index: 2;
}
.haloMask{
    width:100%;
    background-color: #040A20;
    position: absolute;
    z-index: 1;
    top:130px;
    background:linear-gradient(rgba(4,10,32, 1) 0%,rgba(4,10,32,1) 90%,rgba(4,10,32,0) 100%);
    height:330px;
    //mask缩小
    animation: maskNarrow 3s ;
    animation-fill-mode:forwards;
    transform-origin:top
    // .haloMaskTop{
    //     width:100%;
    //     height:100%;
    //     background-color: #040A20;

    // }
    // .haloMaskBottom{
    //     width:100%;
    //     height:20px;
    //     background-color: #040A20;
    //     opacity: 0.5;
    // }
}
// #LettertextHaolo{
//     width:349px;
//     height:389px;
//     background-size: cover;
//     position: absolute;
//     top:100px
// }

.LettertextOuter{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height:100vh;
    z-index: 1;
    .Lettercontent{
        position: absolute;
        top: 18%;
        width:100%;
        height:350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 55px;
        .letterText{
            width:80%;
            height:100%;
            color: white;
            font-family: 'YouSheBiaoTiHei';
            display: flex;
            flex-direction: column;
            align-items: center;
            .letterTextTitle{
                color: #D0FDF8;
                font-size: 28px;
                flex:2;
                width:80%;
                margin-top: 18px;
                text-shadow: 0px 0px 10px rgba(56, 255, 255, 0.6)

            }
            #letterTextTitle::after{
                content: "_";
                font-size: 18px;
                display: inline-block;             
                font-weight: lighter;
                animation: flicker 0.5s infinite;                
            }
            .letterTextContent{
                margin-top: 35px;
                font-size: 18px;
                width:64%;
                flex:8;
                margin-left: 10px;
                line-height: 23.4px;

            }
            #letterTextContent::after{
                content: "_";
                font-size: 18px;
                display: inline-block;             
                font-weight: lighter;
                animation: flicker 0.5s infinite;
            }
        }
        #bigfirefly1{
            position: absolute;
            left:25px;
            top:210px;
            width:35px;
            height:70px;
            transform: rotate(37deg);
        }
        #bigfirefly2{
            position: absolute;
            left:270px;
            top:260px;
            width:30px;
            height:60px;
        }
        #bigfirefly3{
            position: absolute;
            left:280px;
            top:260px;
            width:25px;
            height:50px;
            transform: rotate(63deg);
        }
        #bigfirefly4{
            position: absolute;
            left:15px;
            top:230px;
            width:25px;
            height:50px;
            transform: rotate(163deg);
        }
        #bigfirefly5{
            position: absolute;
            left:55px;
            top:240px;
            width:25px;
            height:50px;
        }
        #right_opacity_firefly{
            position: absolute;
            right:70px;
            top:280px;
            width:25px;
            height:50px;
        }
        #right_opacity_firefly1{
            position: absolute;
            left:30px;
            top:290px;
            width:25px;
            height:50px;
        }
        #right_opacity_firefly2{
            position: absolute;
            right:70px;
            top:280px;
            width:25px;
            height:50px;
        }
        .halo{
            z-index: 0;
            width:349px;
            height:389px;
            background-size: cover;
            position: absolute;
            bottom:-49px;
        }
        .grass{
            z-index: 0;
            width:307px;
            height:125px;
            background-size: cover;
            position: absolute;
            bottom:25px
        }
        #Link{
            display: flex;
            justify-content: center;
            .bottomButton{
                z-index: 10;
                margin-top: 20px;
                position: absolute;
                width:60%;
                height:50px;
                #start_adventure{
                    color:white;
                    position: absolute;
                    top:3px;
                    left:70px;
                    font-family: 'YouSheBiaoTiHei';
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 400;
                    z-index: 10;
                }
                img:nth-child(1){
                    width: 89px;
                    height: 93px;
                    background-size: cover;
                    position: absolute;
                    top:-50px
                }
                img:nth-child(3){
                    width: 165.5px;
                    height: 44px;
                    background-size: cover;
                    position: absolute;
                    left:30px;
                    
                }
                .slash1{
                    width: 27px;
                    height: 46px;
                    left: 177px;
                    top: 0px;
                    background: rgba(70, 255, 244, 0.8);
                    //box-shadow: 0px 0px 4px #63FFFF, inset -1px 0px 0.5px rgba(198, 254, 255, 0.8);
                    position: absolute;
                    clip-path: polygon(72% 7%, 90% 7%, 23% 91%, 5% 91%);
                    //box-shadow: 0px 0px 4px 0px #63FFFF;
                    box-shadow: -1px 0px 0.5px 0px #C6FEFFCC inset;
        
                }
                .slash2{
                    width: 27px;
                    height: 46px;
                    left: 185px;
                    top: 0px;
                    background: rgba(70, 255, 244, 0.8);
                    //box-shadow: 0px 0px 4px #63FFFF, inset -1px 0px 0.5px rgba(198, 254, 255, 0.8);
                    position: absolute;
                    clip-path: polygon(72% 7%, 90% 7%, 23% 91%, 5% 91%);
                    //box-shadow: 0px 0px 4px 0px #63FFFF;
                    box-shadow: -1px 0px 0.5px 0px #C6FEFFCC inset;
        
                }
                img:nth-child(4){
                    width: 27px;
                    height: 46px;
                    background-size: cover;
                    position: absolute;
                    right:10px;
                    transform: rotate(3deg);
                    box-shadow: 0px 0px 4px 0px #63FFFF;
                    box-shadow: -1px 0px 0.5px 0px #C6FEFFCC inset;
                }
                img:nth-child(5){
                    width: 27px;
                    height: 46px;
                    background-size: cover;
                    position: absolute;
                    right:20px;
                    transform: rotate(3deg);
                    box-shadow: 0px 0px 4px 0px #63FFFF;
                    box-shadow: -1px 0px 0.5px 0px #C6FEFFCC inset;
        
                }
            }
        }
    }
}