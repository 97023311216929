@font-face{
    font-family:'YouSheBiaoTiHei';
    src:url(../../assets//fonts/YouSheBiaoTiHei-2.ttf)
}
.background{
    width:100%;
    height:100vh;
    background-color: #040A20;
    display: flex;
    justify-content: center;
    .ipt{
        position: absolute;
        top: 23%;
        width:100%;
        height:200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        #grass{
            width: 346.91px;
            height: 141.25px;
        }
        .halo{
            position: absolute;
            top:90px;
            width: 312.55px;
            height: 75.09px;
            background: linear-gradient(0deg, rgba(136, 255, 255, 0.6) 0%, rgba(93, 240, 240, 0) 94.63%);
            filter: blur(30.4433px);
        }
        #symbol_input{
            background-color: transparent;
            outline:none;
            color: #91BEB9;
            border-top:0px;
            border-left:0px;
            border-right:0px;
            border-color: #A0FFF9;
            font-size: 25.3695px;
            font-family: 'YouSheBiaoTiHei';
            width: 272px;
            text-align: center;
            margin-top: -41px;
            z-index: 2;
        }
        #alert{
            position: absolute;
            bottom:-10px;
            font-size: 16px;
            color: #6FBEF7;
            font-family: 'YouSheBiaoTiHei';
        }
    }
    #symbol_input::placeholder{
        color:#91BEB9;
        font-size: 25.3695px;
    }
    .bottomButton{
        top:260px;
        position: absolute;
        width:60%;
        #start_adventure{
            color:white;
            position: absolute;
            top:3px;
            left:70px;
            font-family: 'YouSheBiaoTiHei';
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            text-shadow: 0px 0px 10.1478px #38FFFF;
        }
        img:nth-child(1){
            width: 89px;
            height: 93px;
            background-size: cover;
            position: absolute;
            top:-50px
        }
        img:nth-child(3){
            width: 165.5px;
            height: 44px;
            background-size: cover;
            position: absolute;
            left:30px;
            
        }
        .slash1{
            width: 27px;
            height: 46px;
            left: 177px;
            top: 0px;
            background: rgba(70, 255, 244, 0.8);
            //box-shadow: 0px 0px 4px #63FFFF, inset -1px 0px 0.5px rgba(198, 254, 255, 0.8);
            position: absolute;
            clip-path: polygon(72% 7%, 90% 7%, 23% 91%, 5% 91%);
            //box-shadow: 0px 0px 4px 0px #63FFFF;
            box-shadow: -1px 0px 0.5px 0px #C6FEFFCC inset;
    
        }
        .slash2{
            width: 27px;
            height: 46px;
            left: 185px;
            top: 0px;
            background: rgba(70, 255, 244, 0.8);
            //box-shadow: 0px 0px 4px #63FFFF, inset -1px 0px 0.5px rgba(198, 254, 255, 0.8);
            position: absolute;
            clip-path: polygon(72% 7%, 90% 7%, 23% 91%, 5% 91%);
            //box-shadow: 0px 0px 4px 0px #63FFFF;
            box-shadow: -1px 0px 0.5px 0px #C6FEFFCC inset;
    
        }
        img:nth-child(4){
            width: 27px;
            height: 46px;
            background-size: cover;
            position: absolute;
            right:10px;
            transform: rotate(3deg);
            box-shadow: 0px 0px 4px 0px #63FFFF;
            box-shadow: -1px 0px 0.5px 0px #C6FEFFCC inset;
        }
        img:nth-child(5){
            width: 27px;
            height: 46px;
            background-size: cover;
            position: absolute;
            right:20px;
            transform: rotate(3deg);
            box-shadow: 0px 0px 4px 0px #63FFFF;
            box-shadow: -1px 0px 0.5px 0px #C6FEFFCC inset;
    
        }
    }
    #loadingPage{
        width:100%;
        height:20px;
        display: flex;
        justify-content: center;
        position: relative;
        top:46%;
        .progressBar{
            width: 60%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .ant-progress{
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .finish{
                position: relative;
                top:15px;
                color: rgba(198, 254, 255, 0.8);
                font-family: 'YouSheBiaoTiHei';
            }
        }
    }
}