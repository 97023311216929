@keyframes treeDisappear {
    from{opacity: 1;}
    to{opacity: 0;}
}
@keyframes coverShow {
    from{opacity: 0;}
    to{opacity: 1;}
}
.coverShow{
    animation: coverShow 1s forwards;
}
.treeDisappear{
    animation: treeDisappear 2s forwards;
}

.three_question{
    width: 100%;
    height: 669px;
    position: fixed;
    // bottom: 1020px;
    // z-index: 0;
    top: 1225px;
    // .coverPage_three{
    //     position: fixed;
    //     top: 1420px;
    //     width: 100vw;
    //     height: 25vh;
    //     z-index: -5;
    //     // background-color: pink
    //     background: url('../../../assets/image/question/zhegaiceng.png') ;
    //     opacity: 1;
    // }
    .three_question_title{
        width: 310px;
        height: 68px;
        font-family: 'YouSheBiaoTiHei';
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        // letter-spacing: 0em;
        text-align: left;     
        color: #D0FDF8;
        text-shadow: 0px 0px 10px rgba(56, 255, 255, 0.6);
        // box-shadow: 0px 0px 10px 0px #38FFFF99;
        margin-left: 35px;
        margin-top: 127px;
        display: flex;
        align-items: baseline;
        img{
            margin-right: 10px;
        }
    }
   .tree{
       img{ 
        width:352px;
        height:193px;
        position: absolute;
        top: 163.5px;
        // z-index: -10 !important;
       }
   }
    .three_question_options{
        position: absolute;
        width: 335px;
        height: 200px;
        bottom: 120px;
        left: 20px;
        z-index: 18;
        .three_question_config{
            width: 65vw;
            height: 80px;
            font-family: 'YouSheBiaoTiHei';
            z-index: 18;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            color:  #E0FEFC;
            text-shadow: 0px 0px 10px rgba(56, 255, 255, 0.6);  
            box-sizing: border-box;
            span{
                margin-left: 30px;
            }
         
        }
        .three_question_one{
            @extend  .three_question_config;
            // margin-left: 50px;
            // left: 40px;
            z-index: 18;
        }
        .three_question_two{
            @extend  .three_question_config;
            margin-top: 10px;
            z-index: 18;
        }
    }
    .three_previous_question{
       
            position: fixed;
            top: 1830px;
            left: 15px;
            width: 56px;
            height: 26px;
            font-family: 'YouSheBiaoTiHei';
            // background-color: greenyellow;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 26px;
            color: #A1E0DC;
            display: block;
            z-index: 18;
        
    }
}