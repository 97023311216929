@font-face{
    font-family:'YouSheBiaoTiHei';
    src:url(../../assets//fonts/YouSheBiaoTiHei-2.ttf)
}
#join_us_gradient{
    position: absolute;
    width: 373.94px;
    height: 97vh;
    background-size: cover;
    z-index: 5;
}
#join_us_background{
    position: absolute;
    width: 100%;
    height:100vh;
    z-index: 2;
    background: url('../../assets/image/Join_us/mashroom.png') no-repeat;
    background-position: -5px 370px;
    background-size:380px 330.61px;
}
#join_us_solidColor{
    position: absolute;
    width: 100%;
    height:100vh;
    z-index: 1;
    background-color: #040A20;
}
.outer{
    position: absolute;
    width:100%;
    height:100vh;
    position: relative;
    z-index: 10;
    .join_us_top{
        z-index: 2;
        #back_png{
            width: 26px;
            height: 39px;
            background-size: cover;
            margin-top: 4vh;
            margin-left: 18px;
        }
    }
    .join_us_entry{
        z-index: 1;
        margin-top: 3vh;
        #join_us_entry{
            font-family: 'YouSheBiaoTiHei';
            font-size: 35px;
            color: #9BFFF9;
            text-shadow: 0px 0px 10px rgba(160, 255, 249, 0.6);
            margin-left: 23px;
        }
        .qrCode{
            margin-top: 4.8vh;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            #qrCode{
                border-radius: 5px;
                width: 120px;
                height: 120px;
                background-size: cover;
            }
            #under_qrCode{
                font-size: 2.4vh;
                font-family: 'YouSheBiaoTiHei';
                color: #A7FAFD;
                position: relative;
                top:20px;
            }
        }
    }
    .learn_more{
        margin-top: 8vh;
        #learn_more{
            font-family: 'YouSheBiaoTiHei';
            font-size: 35px;
            color: #9BFFF9;
            text-shadow: 0px 0px 10px rgba(160, 255, 249, 0.6);
            margin-left: 23px;
        }
        #group1{
            display: block;
            font-family: 'YouSheBiaoTiHei';
            font-size: 16px;
            color: #9BFFF9;
            margin-top: 4.6vh;
            margin-left: 23px;
            z-index: 10;
        }
        #group2{
            display: block;
            font-family: 'YouSheBiaoTiHei';
            font-size: 16px;
            color: #9BFFF9;
            margin-top: -2vh;
            margin-left: 23px;
            z-index: 10;

        }
    }
    
}
#join_us_mashroom{
    position: absolute;
    margin-top: -156px;
    width: 100%;
    height: 300px;
    background-size: cover;
}
#static_firefly{
    width: 165px;
    height: 130px;
    background-size: cover;
    position: absolute;
    margin-top: -45px;
    left: 40px;
}
#join_us_halo{
    width: 100%;
    height: 111.5px;
    background-size: cover;
    position: absolute;
    margin-top: 30px;
}
.join_us_mashroomouter{
    position: absolute;
    width: 100%;
    height:23vh;
    display: flex;
    align-items: center;
    z-index: 10;
}