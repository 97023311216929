@font-face{
    font-family:'YouSheBiaoTiHei';
    src:url(../../assets/fonts/YouSheBiaoTiHei-2.ttf)
}
*{
    margin: 0;
    padding: 0;
}
.question_hidden{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    .question_index_content{
        width: 299px;
        height: 52px;
        position: absolute;
       margin-top: 90px;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        box-sizing: border-box;
        font-weight: 400;
        line-height: 26px;
        font-family:'YouSheBiaoTiHei';
        z-index: 12;
        left: 10%;
            color: #D0FDF8; 
            text-shadow: 0px 0px 10px rgba(56, 255, 255, 0.6);          
    }
}
.coverPage{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 226px;
    z-index: 3;
    background-image: url('../../assets/image/question/cover.png');
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.6) 0.01%, rgba(0, 0, 0, 0) 100%);
    opacity: 1;
}
.question_index{
    width: 375px ;
    height: 100vh !important;
    background-repeat: no-repeat;
    background-size:cover;
    z-index: 2;
    position: absolute;
    bottom: 0;
    // background-image: url('../../assets/image/question/complete.png');
    display: flex;
    align-items: center;
    flex-direction: column;
    img{
        background-repeat: no-repeat;
    background-size:cover;
    position: absolute;
    bottom: 0px;
    width: 100vw;
    // height: 100vh;
    }
  
  

}