
@font-face{
    font-family:'YouSheBiaoTiHei';
    src:url(../../assets//fonts/YouSheBiaoTiHei-2.ttf)
}
@font-face{
    font-family:'pinfFanf_SC';
    src:url(../../assets//fonts/pingFang_SC.ttf)
}
@keyframes rotate1{
    from{
        transform:rotate(0deg)
    }
    to{
        transform: rotate(90deg);
    }
}

@keyframes rotate2{
    from{
        transform:rotate(90deg)
    }
    to{
        transform:rotate(0deg);
    }
}
.scorll{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
}
#gradient{
    position: absolute;
    top:410px;
    left:-40px;
    width: 377.02px;
    height: 208.94px;
    background-size: cover;
}
// #bigGradient{
//     width: 378px;
//     height: 686.19px;
//     background-size: cover;
//     position: absolute;
//     left:-38px;
//     z-index: 1;
//     margin-top: -60px;
// }
#save_img{
    position: absolute;
    z-index: 10;
    transform:scale(0.8,0.8);
}
#symbol_img_style{

    position: absolute;
    width: 100vw;
    height:100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background:rgba(0,0,0,0.5);   
    // #save_img{
        
    // }
}
#close{
    position: absolute;
    z-index: 130;
    right: 36px;
    width:33px;
    height:33px;
    background-size: cover;
}
.flashes{
    width:100%;
    height:100vh;
    position: absolute;
    background-color: white;
    z-index: 100;
}
#underqrCode{
    position: absolute;
    top:83vh;
    left:56%;
    font-size: 13px;
    color: #85C5CC;
    font-family: 'PingFang SC';
    z-index: 130;
}
#outerBackground{
    background-color: #031833;
    width: 100%;
    height:100vh;
    position: absolute;
    z-index: -1;
}
#screenshot{
    // background-image:url('../../assets/image/result/fabric.png'),url('../../assets/image/result/small_grass.png'),url('../../assets/image/result/background_grass.png'),url('../../assets/image/result/bigGradient.png');
    // background-repeat:no-repeat,no-repeat,no-repeat,no-repeat;
    // background-size:335px 298px ,85px 98px,361px 202px,373.94px 686.19px;
    // background-position:50% 15px, 40px 215px, 0 110px,0 0px;
    background-image:url('../../assets/image/result/small_grass.png'),url('../../assets/image/result/background_grass.png'),url('../../assets/image/result/bigGradient.png');
    background-repeat:no-repeat,no-repeat,no-repeat;
    background-size:85px 98px,361px 202px,373.94px 686.19px;
    background-position:40px 215px, 0 110px,0 0px;
    width: 100vw;
    // height:100vh;
    display:flex;
    background-color: #031833;
    flex-direction:column;
    align-items:center;
}
.res_outer{
    width: 100vw;
    background-color: #031833;
    display: flex;
    flex-direction: column;
    align-items: center;
    // background-image:url('../../assets/image/result/background_grass.png'),url('../../assets/image/result/bigGradient.png');
    // background-repeat:no-repeat,no-repeat;
    // background-size: 361px 202px,373.94px 686.19px;
    // background-position: 0 110px,0 0px;
    #background_grass{
            width:361px;
            height:202px;
            position: absolute;
            left: -36px;
            top: 60px;
        }
        .content{
            // background-image:url('../../assets/image/result/small_grass.png');
            // background-repeat:no-repeat ;
            // background-size: 85px 98px;
            // background-position: 0 159px;
            display: flex;
            flex-direction: column;
            width: 80%;
            // height:350px;
            position: relative;
            margin-top: 58px;
            #small_grass{
                position: absolute;
                width: 85px;
                height: 98px;
                top:160px;
                left:-5px;
            }
            #first_line{
                z-index: 2;
                margin-top: 10px;
                margin-left: 8px;
                color: #96DDDF;
                font-size: 16px;
                font-family: 'pinfFanf_SC';
            }
            #second_line{
                z-index: 2;
                margin-left: -10px;
                width: 243.6px;
                height: 68.4px;
                // font-size: 44px;
                // font-family: 'YouSheBiaoTiHei';
                // text-shadow: 0px 0px 20px rgba(160, 255, 249, 0.6);
                // color:#C5FFFD;
                // -webkit-text-fill-color:transparent; 
                // background-clip:text; 
                // background-image: linear-gradient(161.87deg, #C9DDFF 100%, #C5FFFD 100%, #E0EEFB 100%);
                // background-image: -o-linear-gradient(161.87deg, #C9DDFF 100%, #C5FFFD 100%, #E0EEFB 100%);
                // background-image: -moz-linear-gradient(161.87deg, #C9DDFF 100%, #C5FFFD 100%, #E0EEFB 100%);
                // background-image: -webkit-linear-gradient(161.87deg, #C9DDFF 100%, #C5FFFD 100%, #E0EEFB 100%);
                
            }
            #third_line{
                width:270px;
                z-index: 2;
                display: flex;
                justify-content:space-between;
                color: #9FFFFF;
                font-family: 'YouSheBiaoTiHei';
                font-size: 20px;
                margin-left: 7px;
                margin-top: -8px;
                z-index: 2;
                .third_line1{
                z-index: 2;
                    .third_line1_blueline{
                        width:105%;
                        height:4px;
                        background-color: #3068FF;
                        border-radius: 30px;
                        position: relative;
                        top:-11px;
                        z-index: -1;
                        
                    }
                }
            .third_line2{
                z-index: 2;
                position: relative;
                .third_line2_blueline{
                    position: relative;
                    top:-11px;
                    width:105%;
                    height:4px;
                    background-color: #3068FF;
                    border-radius: 30px;
                    z-index: -1;

                }
            }
            .third_line3{
                z-index: 2;
                position: relative;
                .third_line3_blueline{
                    width:105%;
                    height:4px;
                    background-color: #3068FF;
                    border-radius: 30px;
                    position: relative;
                    top:-11px;            
                    z-index: -1;

                }
            }
        }
        #fourth_line{
            z-index: 2;
            color: #A7FAFD;
            font-family: 'YouSheBiaoTiHei';
            font-size: 16px;
            margin-left: 6px;
            margin-top: 8px;
            line-height: 20.8px;
        }
        #fifth_line{
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 30px;
            #halo_line{
                position: relative;
                top:-8px;
                height:1px;
                width: 58.5px;
                background-color: #A0FFF9;
                display: inline-block;
            }
            #sign{
                position: relative;
                top:-8px;
                color: #A0FFF9;
                font-family: 'YouSheBiaoTiHei';
                font-size: 16px;
                margin-left: 10px;
            }
        }
        

        #fabric_pic{
            width:335px;
            height:298px;
            background-size: cover;
            z-index: 2;
            position: absolute;
            top:-42px;
            left:-22px
        }
        #butterfly{
            width: 89.7px;
            height: 102.785px;
            background-size: cover;
            position: absolute;
            top:0px;
            right:-3px;
            z-index: 2;
        }
    }
    .bottom_content{
        // margin-top: -20px;
        width:90%;
        height: 80vw;
        position: relative;
        #res_static_firefly{
            width: 101.9%;
            height: 56.9vh;
            background-size: cover;
            position: absolute;
            margin-top:-23.5vh;
        }
        .bottom_top{
            color:#6DB5FD;
            font-size: 15px;
            font-family: PingFang SC;
            margin-top: 20px;
            #small_icon{
                margin-left: 5px;
                width:16px;
                height:16px;
                background-size: cover;
            }
            .department{
                margin-left: 5px;
                font-size: 16px;
                font-family: YouSheBiaoTiHei;
                color: #6DB5FD;
            }
        }
        #save_pic{
            z-index: 5;
            width: 104px;
            height: 38px;
            line-height: 38px;
            border: 1px solid #96C3ED;
            box-sizing: border-box;
            border-radius: 32px;
            margin-top:30px;
            color:#96C3ED;
            text-align: center;
            font-size: 20px;
            font-family: 'YouSheBiaoTiHei';
            position: absolute;
            left:6px;
        }
        .save_pic_empty{
            z-index: 6;
            width: 104px;
            height: 38px;
            position: absolute;
            top: 80px;
            left:8px;
        }
        .bottom_body{
            width:100%;
            height:200px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            z-index: 3;
            position: relative;
            top:-10px;
            .qrCode{
                display: flex;
                flex-direction: column;
                width:79px;
                align-items: center;
                position: absolute;
                left:18px;
                margin-top: 3px;
                #qrCode{
                    width:79px;
                    height:79px;
                    background-size: cover;
                }
                .underqrCode{
                    width: 110px;
                    font-family: 'YouSheBiaoTiHei';
                    font-size: 14px;
                    color: rgba(198, 254, 255, 0.8);
                    text-align: center;
                }
            }
            .radar{
                position: absolute;
                right:0px;
                top:-30px;
                width:200px;
                height:200px;
                z-index: 2;
                font-family: 'YouSheBiaoTiHei';
            }
        }
        .mashroomOuter{
            position: relative;
            width: 364px;
            height: 157.5px;
            overflow: hidden;
            z-index: 4;
            left:-18px;
            // transition: all 1s;
            // transform: translateX(-18px) translateY(-200px);
            #mashroom{
                width: 364px;
                height: 157.5px;
                background-size: cover;
            }
        }
    }
    #splitLine{
        position: relative;
        left:5px;
        width: 332px;
        height: 30px;
        background-size: cover;
    }
    .department_introduction{
        width:100%;
        .department_header{
            position: relative;
            margin-top:10px;
            left:5px;
            margin-left:25px;
            font-family: 'YouSheBiaoTiHei';
            text-shadow: 0px 0px 10px rgba(160, 255, 249, 0.6);
            font-size: 35px;
            color: #AFF1FF;
            text-align: left;
        }
        .department{
            margin-top: 43px;
            position: relative;
            top:30px;
            width: 100%;
            z-index: 2;
            .department__header{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .department_header_top{
                    justify-content: space-around;
                    position: relative;
                    width: 100%;
                    .support{
                        height:100%;
                        width:100%;
                    }
                    .double_slash{
                        width:30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        left:18px;
                        top:10px;
                        position: absolute;
                        z-index: 9;
                        .slash{
                            width: 3.9px;
                            height: 17.04px;
                            background: #D0FDF8;
                            box-shadow: 0px 0px 1.48147px #63FFFF, inset -0.370368px 0px 0.185184px rgba(198, 254, 255, 0.8);
                            transform: rotate(-180deg);
                            // clip-path: polygon(70% 0, 100% 0%, 40% 100%, 10% 100%);
                            transform: skewX(-30deg);
                            margin-left: 5px;
                        }

                    }
                    .symbol_header{
                        position: relative;
                        left:49px!important;
                        font-family: 'YouSheBiaoTiHei';
                        font-size: 25px;
                        color: #D0FDF8;
                        z-index: 2;
                    }
                    .percent{
                        position: absolute;
                        top:0px;
                        color: #96C0FF;
                        font-family: 'YouSheBiaoTiHei';
                        font-size: 18px;
                        line-height: 39px;
                        z-index: 2;
                    }
                    .blueLine{
                        position: absolute;
                        top: 10px;
                        left:47.5px!important;
                        background: #0041A4;
                        width: 228px;
                        height: 18px;
                        clip-path: polygon(2% 0, 100% 0%, 98% 100%, 0% 100%);
                        z-index: 1;
                    }
                    .triangle1{
                        position: absolute;
                        right:10px;
                        top: 13px;
                        width: 20px;
                        height: 14.4px;
                        background-size: cover;
                        animation-fill-mode:forwards;
                        // animation: rotate1 0.5s;
                        transform: rotate(90deg);
                        z-index: 5;

                    }
                    .triangle2{
                        position: absolute;
                        right:10px;
                        top: 13px;
                        width: 20px;
                        height: 14.4px;
                        background-size: cover;
                        animation: rotate2 0.5s;
                        animation-fill-mode:forwards;
                        z-index: 5;
                    }
                    .triangle3{
                        position: absolute;
                        right:10px;
                        top: 13px;
                        width: 20px;
                        height: 14.4px;
                        background-size: cover;
                        animation-fill-mode:forwards;
                        animation: rotate1 0.5s;
                        transform: rotate(90deg);
                        z-index: 5;

                    }
                }
                .default_halo{
                    position: absolute;
                    width:332px;
                    height:75px;
                    top:16px;
                    left:20px;
                }
                .motto{
                    color: #91CAFF;
                    font-size: 20px;
                    font-family: YouSheBiaoTiHei;
                    position: relative;
                    height:50px;
                    line-height: 75px;
                    text-align: right;
                    margin-right: 9px;
                    .department_logo{
                        position: absolute;
                        top:6px;
                        right:5px;
                        width: 90px;
                        height: 39px;
                        background-size: cover;
                    }
                }
            }
            .department_content{
                margin-left: 35px;
                margin-top: 20px;
                width: 83%;
                font-size: 16px;                
                color: #AEDEED;
                font-family: 'YouSheBiaoTiHei';
            }
            .department_halo{
                top:-100px;
                left:20px;
                position: absolute;
                width: 334px;
                height: 191px;
                background: linear-gradient(0deg, rgba(136, 255, 255, 0.4) 0%, rgba(93, 240, 240, 0) 94.63%);
                filter: blur(30px);
                background: linear-gradient(0deg, rgba(136, 255, 255, 0.4) 0%, rgba(93, 240, 240, 0) 94.63%);

            }
        }
        .res_footer{
            width:100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            height:150px;
            top:40px;
            background-color: #031833;
            z-index:1;
            .bottomBtns{
                margin-top: 80px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                .bottomBtn{
                    text-align: center;
                    width: 128px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 26px;
                    font-family: 'YouSheBiaoTiHei';
                    font-size: 18px;
                    color: #A0FFF9;
                    border: 1px solid #5FFBFF !important;
                    // background-color: rgba(3, 24, 51, 0.7)!important;
                    // background-color: #031833B2;
                    background-color: #031833B2;
                    z-index: 2;
                }
            }
            #bottom_halo{
                position: absolute;
                bottom: 0px;
                width: 100%;
                height: 78px;
            }
            #bottom_grass{
                position: relative;
                top:-40px;
                width: 351px;
                height: 78px;
                background-size: cover;

            }
            #bottomPlaceholder{
                width:100%;
                height:120px;
            }
        }
    }
}