@keyframes shakeY1 {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translate3d(0, -5px, 0);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translate3d(0, 5px, 0);
    }
}
.demo{
    width: 50px;
    height: 50px;
    position: fixed;
    right:10px;
    top:15px;
    z-index: 5;
    display: flex;
    justify-content:center;
    #musicIcon{
        width:76px;
        height:76px;
        background-size: cover;
        margin-top: -14px;
        // animation-name: shakeY1;
        // --animate-duration: 10s;
        // animation-iteration-count:infinite;
    }
}
.music_empty{
    position: absolute;
    right:0px;
    height:80px;
    width:80px;
    z-index: 5;
}
#firefly1{
    position: absolute;
    right:40px;
    top:25px;
    width:25px;
    height:50px;
    transform: rotate(30deg);
}
#firefly2{
    position: absolute;
    right:12px;
    top:16px;
    width:25px;
    height:50px;
    transform: rotate(90deg);
}
#firefly3{
    position: absolute;
    right:33px;
    top:45px;
    width:25px;
    height:50px;
    transform: rotate(60deg);
}
#changeIconState{
    height:50px;
    line-height: 50px;
    text-align: center;
    z-index: -1;
}
#musicPlayer{
    background-color: aqua;

}
.audio{
    display:none;
}
.cheat_interactive{
    z-index: 10;
    position: absolute;
    left: 112px;
    top: 50%;
    width:150px;
    height: 50px;
    color: pink;
}