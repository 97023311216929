@font-face{
    font-family:'YouSheBiaoTiHei';
    src:url(../../../assets/fonts/YouSheBiaoTiHei-2.ttf)
}
@keyframes coverDisappear {
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
}
@keyframes coverShow {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
.coverDisappear{
    animation: coverDisappear 3s forwards;
}
.coverShow{
    animation: coverShow 3s forwards;
}
   .changeMusic{
        position: fixed;
        width: 50px;
        height: 50px;
        // background-color: red;
        top: 10px;
        z-index: 111111;
        right: 10px;
    }
.question_page{
    position: absolute;
    width: 375px;
    height:2915px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../../assets/image/question/zipFinally.jpg');
    // position: relative;
    // top:-2250px;
    bottom: 0;
    // bottom: 0px;
    .coverPage{
        position:fixed;
        left: 0;
        width:100vw;
        height: 2915px;
        z-index: -6;
        background-image: url('../../../assets/image/question/zhegaiceng.png');
        opacity: 1;
        display: block;
    }
    // .changeMusic{
    //     position: fixed;
    //     width: 50px;
    //     height: 50px;
    //     background-color: pink;
    //     top: 2600px;
    //     right: 10px;
    // }
    .first_question_unshow{
        display: none;
    }
    .first_question{
        position: fixed;
        // bottom:190px;
        // top: 2400px;
        // background-color: pink;
        width: 100vw;
        height: 320px;
        margin-left: 25px;
        img{
            width: 13px;
            height: 17px;
        }
        .first_question_title{
            font-family: 'YouSheBiaoTiHei';
            font-weight: 400;
            font-style: normal;
            font-size: 26px;
            color: #d0fdf8;
            text-shadow: 0px 0px 10px rgba(56, 255, 255, 0.6);  
            // box-shadow: 0px 0px 10px 0px #38FFFF99;

            // background-color: pink;
        }
        .first_question_content{
            // display: flex;
            // align-items: center;
            width: 340px;
            height: 70px;
            font-family: 'YouSheBiaoTiHei';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            color:  #E0FEFC;
            text-shadow: 0px 0px 10px rgba(56, 255, 255, 0.6);  
            box-sizing: border-box;
            // line-height: (80/667)*100vh;
           
            span{
                margin-left: 30px;
            }
        }
        .first_question_contentOne{
            @extend  .first_question_content;
            margin-top: 50px;
        }
        .first_question_contentTwo{
            @extend  .first_question_content;
            margin-top: -20px
        }
        .first_question_contentThree{
            @extend  .first_question_content;
        
        }

    }
}