
.five_question{
    width: 100%;
    height: 668px;
    position:fixed;
    // background-color:pink;
    top: 324px;
    // .coverPage_five{
    //     position: fixed;
    //     top: 324px;
    //     left: 0;
    //     width: 100vw;
    //     height: 100vh;
    //     // background-color: pink;
    //     z-index: -5;
    //     background-image: url('../../../assets/image/question/zhegaiceng.png');
    //     opacity: 1;
    // }
    .firefly{
        .five_question_firefly1{
            img{
                width: 80px;
                height: 80px;
                top: 200px;
                left: 20px;
                position: absolute;
                // background-color: pink;
                transform: rotate(10deg);
            }
        }
        .five_question_firefly2{
            img{
                width: 60px;
                height: 120px;
                top: 250px;
                left: 10px;
                position: absolute;
                // background-color: pink;
                transform: rotate(10deg);
            }
        }
        .five_question_firefly3{
            img{
                width: 80px;
                height: 80px;
                top: 300px;
                left: 10px;
                position: absolute;
                // background-color: pink;
                transform: rotate(10deg);
            }
        }
    }
    .five_question_title{
        width: 320px;
        height: 68px;
        font-family: 'YouSheBiaoTiHei';
        font-style: normal;
        font-weight: normal;
        font-size: 26px;
        line-height: 34px;
        color: #D0FDF8;
        // box-shadow: 0px 0px 10px 0px #38FFFF99;
        // background-color: pink;
        text-shadow: 0px 0px 10px rgba(56, 255, 255, 0.6);
        display: flex;
        align-items: baseline;
        justify-content: center;
        margin-top: 150px;
        margin-left: 30px;
    }
    .five_question_options{
        width: 330px;
        height: 300px;
        margin: 10px auto;
        .five_question_config{
            font-family: 'YouSheBiaoTiHei';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            // line-height: 26px;
            color: #E0FEFC;
            // line-height: 80px;
            // z-index: 7;
            span{
                margin-left: 30px;
            }
        }
        .five_question_one{
            @extend  .five_question_config;
        }
        .five_question_two{
            @extend  .five_question_config;
            // margin-top: 20px;
            margin-top: -5px;
        }
        .five_question_three{
            @extend  .five_question_config;
            // margin-top: 20px;
            margin-top: -5px;
        }
        .five_question_four{
            @extend  .five_question_config;
            // margin-top: 20px;
            margin-top: -5px;
        }
    }
    .five_previous_question{
        position: fixed;
        top: 950px;
        left: 20px;
        font-family:'YouSheBiaoTiHei';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        color: #A1E0DC;
        // z-index: 7;
    }
}