.four_question{
    width: 100%;
    height: 668px;
    position:fixed;
    top: 750px;
    // .coverPage_four{
    //     position: fixed;
    //     top: 750px;
    //     left: 0;
    //     width: 100vw;
    //     height: 100vh;
    //     // background-color: pink;
    //     z-index: -5;
    //     background-image: url('../../../assets/image/question/zhegaiceng.png');
    //     opacity: 1;
    // }
    .four_question_title{
        width: 297px;
        height: 68px;
        font-family: 'YouSheBiaoTiHei';
        font-style: normal;
        // font-weight: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 34px;
        color: #D0FDF8;
        text-shadow: 0px 0px 10px rgba(56, 255, 255, 0.6);
        // box-shadow: 0px 0px 10px 0px #38FFFF99;
        display: flex;
        align-items: baseline;
        justify-content: center;
        margin-top: 150px;
        margin-left: 30px;
        span{
            margin-left: 5px;
        }
    }
    .four_question_options{
        // width: 300px;
        // height: 300px;
        // background-color: pink;
        // position: fixed;
        // top: 780px;
        .four_question_config{
            font-family: 'YouSheBiaoTiHei';
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            // line-height: 26px;
            color: #E0FEFC;
            // line-height: 70px;
            z-index: 7;
            span{
                margin-left: 30px;
            }
        }
        .four_question_one{
            @extend .four_question_config;
            position: fixed;
            top: 1020px;
            right: 60px;
            
        }
        .four_question_two{
            @extend .four_question_config;
            position: fixed;
            top: 1085px;
            left: 15px
        }
        .four_question_three{
            @extend .four_question_config;
            position: fixed;
            top: 1150px;
            right: 40px;
        }
        .four_question_four{
            @extend .four_question_config;
            position: fixed;
            top: 1220px;
            left: 10px;
        }
        
    }
    .four_previous_question{
        position: fixed;
        top: 1370px;
        left: 20px;
        font-family:'YouSheBiaoTiHei';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        color: #A1E0DC;
        z-index: 7;
    }
}