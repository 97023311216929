.home {
    margin: 0;
    width: 375px;
    height: 100vh;
    background: #040A20;
    #music{
      float:right
    }
    #outerShell{
      position: absolute;   
      top:50%;
      margin-top: -230px;
      display: flex;  
      justify-content: center;  
      width: 100%;
      #letter{
        background-color: pink;
    
      }
    }
  }