@keyframes boxMove {
    from{
        background-position-x: 0;
    }
    
    to{
        background-position-x: -900px;
        // background-position-x: #{(-745/375)*100}vw;
    }
}
.six_question_unshow{
    display: none;
}
.six_question_show{
    display: block;
}
.six_question{
    width: 100%;
    height: 668px;
    position:fixed;
    .coverPage_six{
        position: fixed;
        top:0px;
        left: 0;
        width: 100vw;
        height: 100vh;
        // background-color: pink;
        z-index: -5;
        background-image: url('../../../assets/image/question/zhegaiceng.png');
        opacity: 1;
    }
    .showbox{
        // width: 140px;
        width: 50%;
        transform: translateX(-50%);
        height: 100px;
        position: relative;
        top: 160px;
        left: 50%;
        // background-color: pink;
        // background: url('../../../assets/image/question/box.png');
        background-size: 140px;
        background-repeat: no-repeat; 
        // animation: boxMove 2s  steps(6) forwards;
        img{
            width:515px;
            position: fixed;
            top: 66px;
            left: 50%;
            transform: translateX(-48%);
        
        }
    }
    .static_box{
        // @extend .showbox;
        width: 50%;
        transform: translateX(-50%);
        height: 100px;
        position: relative;
        top: 160px;
        left: 50%;
        // background-color: pink;
        background-size: 140px;
        z-index: -88;
        img{
            z-index: -99;
            width:80px;
            position: fixed;
            top: 66px;
            left: 50%;
            transform: translateX(-40.5%);
        }
    }
    .six_question_title{

        .six_question_title_hint{
            font-family: 'YouSheBiaoTiHei';
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 26px;
            text-align: center;
            color: #D0FDF8;
            text-shadow: 0px 0px 10px rgba(56, 255, 255, 0.6);
            // box-shadow: 0px 0px 10px 0px #38FFFF99;
            width: 299px;
            height: 52px;
            position: fixed;
            top: 86px;
            left: 38px;
        }
        .six_question_title_hint_show{
            display: block;
        }
        .six_question_title_hint_unshow{
            display: none;
        }
        .six_question_title_subject{
            font-family: 'YouSheBiaoTiHei';
            font-style: normal;
            width: 325px;
            font-weight: normal;
            font-size: 26px;
            line-height: 34px;
            color: #D0FDF8;
            // box-shadow: 0px 0px 10px 0px #38FFFF99;
            text-shadow: 0px 0px 10px rgba(56, 255, 255, 0.6);
            display: flex;
            align-items: baseline;
            justify-content: center;
            position: fixed;
            top: 133px;
            left: 27px;
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            span{
                text-align: left;
            }
        }
      
    }
    .six_question_options{
        width: 300px;
        height: 300px;
        .six_question_config{
            font-family: 'YouSheBiaoTiHei';
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            // line-height: 26px;
            z-index: 7;
            color: #E0FEFC;
            line-height: 70px;
            span{
                margin-left: 30px;
            }
        }
        .six_question_one{
            @extend .six_question_config;
            position: fixed;
            top: 260px;
            left: 20px
            
        }
        .six_question_two{
            @extend .six_question_config;
            position: fixed;
            top: 324px;
            right: 50px;
        }
        .six_question_three{
            @extend .six_question_config;
            position: fixed;
            top: 395px;
            left: 20px;
        }
        
    }
    .six_previous_question{
        position: fixed;
        top: 613px;
        left: 20px;
        z-index: 7;
        // background-color: pink;
        font-family: 'YouSheBiaoTiHei';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        color: #A1E0DC;
    }
}