@font-face{
    font-family:'YouSheBiaoTiHei';
    src:url(../../assets//fonts/YouSheBiaoTiHei-2.ttf)
}
@keyframes move1{
    from{
        transform: translate(0,-100%);
    }
    to{
        transform:translate(0px,1%)
    }
}
@keyframes move2{
    from{
        transform: translate(0,100%);
    }
    to{
        transform:translate(0,-1%)
    }
}
//自定义fadeInUp
@keyframes fadeInUp1 {
    from {
        opacity: 0;
        transform: translate3d(0, 30%, 0);
    }
  
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
@keyframes offTV {
    0%{
        transform: scale(1,0);
    }
    5%{
        transform: scale(0.95,0.1);
    }
    10%{
        transform: scale(0.9,0.2);
    }
    15%{
        transform: scale(0.85,0.3);
    }
    20%{
        transform: scale(0.8,0.4);
    }
    25%{
        transform: scale(0.75,0.5);
    }
    30%{
        transform: scale(0.7,0.6);
    }
    35%{
        transform: scale(0.65,0.7);
    }
    40%{
        transform: scale(0.6,0.8);
    }
    45%{
        transform: scale(0.55,0.9);
    }
    50%{
        transform: scale(0.5,1);
    }
    55%{
        transform: scale(0.45,0.9);
    }
    60%{
        transform: scale(0.4,0.8);
    }
    60%{
        transform: scale(0.35,0.7);
    }
    70%{
        transform: scale(0.3,0.6);
    }
    75%{
        transform: scale(0.25,0.5);
    }
    80%{
        transform: scale(0.2,0.4);
    }
    85%{
        transform: scale(0.15,0.3);
    }
    90%{
        transform: scale(0.10,0.2);
    }
    95%{
        transform: scale(0.05,0.1);
    }
    100%{
        transform: scale(0,0);
    }
}
.fadeInUp1 {
    animation-name: fadeInUp1;
}

.offTV_mask{
    position: absolute;
    width:100%;
    height:667px;
    z-index: 10;
    .content_top{
        background-color: #040A20;
        height:50%;
        animation: move1 0.5s;
    }
    .content_bottom{
        background-color: #040A20;
        height:50%;
        animation: move2 0.5s;
    }
    .ellipse{
        clip-path: ellipse(50% 11% at 50% 50%);
        background-color: white;
        height:20px;
        width:100%;
        float: left;
        margin-top: 0px;
        animation: offTV 0.3s;
        
    }
}
#outerShell{
    .grass_nofirefly{
        position: absolute;
        display: block!important;
        top: 190px;
        left:27px;
        width:316px;
        height:129px;
        background-size: cover;
        z-index: 2;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    position: absolute;
    top:320px;
    z-index: 2;
    #redrock_logo{
        font-family:'YouSheBiaoTiHei';
        color:white;
        font-size: 28px;
        color: #D0FDF8;
        text-shadow: 0px 0px 10px #38FFFF;
    }
    .Letter_innercontent{
        text-align: center;
        .letter_empty{
            position: absolute;
            left: 0px;
            width:100%;
            height: 100%;
            z-index: 10;
        }
        .letterShell{
            position: absolute;
            width: 271px;
            height: 195px;
            left: 54px;
            bottom: 0;
            background: #283B4F;
            border: 0.2px solid #A2FFFF;
            box-sizing: border-box;
            box-shadow: 0px 0px 4px #CAFCFF;
            filter: blur(1px);
        }
        #Letter_box{
           margin-top: 30px;
           width: 271px;
            height: 195px;
            display: block!important;
            display: flex;
            flex-direction: column;
            z-index: 2;
            padding-top: 50px;
            //filter: blur(1px);
            .Letter_box_text{
                font-family: 'YouSheBiaoTiHei';
                font-size: 22px;
                color: white;
                //margin-top: 44px;
            }
            .Letter_box_btn{
                width: 115px;
                height: 38px;
                background-color: #72B5BE;
                color: white;
                font-family: 'YouSheBiaoTiHei';
                font-size: 24px;
                border: none;
                box-shadow: 0px 0px 10px rgba(56, 255, 255, 0.3), inset 1px 1px 0px rgba(255, 255, 255, 0.25);
                transform: matrix(0.99, 0, -0.12, 1, 0, 0);
                margin-top: 30px;
                z-index: 12;
            }
            .firefly{
                position: absolute;
                left:0
            }
            #firefly1{
                position: absolute;
                left:230px;
                top:110px;
                width:25px;
                height:50px;
                transform: rotate(30deg);
            }
            #firefly2{
                position: absolute;
                top:120px;
                left:15px;
                width:25px;
                height:50px
            }
            #firefly3{
                position: absolute;
                right:30px!important;
                top:155px!important;
                width:25px;
                height:50px;
                transform: rotate(60deg);
            }
            #firefly4{
                position: absolute;
                right:20px!important;
                bottom:5px!important;
                width:25px;
                height:50px
            }
            #firefly5{
                position: absolute;
                left:35px!important;
                bottom:-5px!important;
                width:25px;
                height:50px
            }
            .Letter_box_grass{
                width:316px;
                height:129px;
                background-size: cover;
                position: absolute;
                left: -25px;
                bottom: 0px;
                display: block!important;
            }
        }
    }
}
