@keyframes wizardDisappear {
    from{opacity: 1;}
    to{opacity: 0;}
}
@keyframes squareDisappear {
    0%{transform: scale(1);opacity: 1;}
    50%{transform: scale(.5);opacity: .6;}
    100%{transform: scale(.3);opacity: 0;}
}
@keyframes coverDisappear {
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
}
.wizardDisappear{
    animation: wizardDisappear 2.5s forwards;
}
.squareDisappear{
    animation: squareDisappear 2s forwards;
}
.coverDisappear{
    animation: coverDisappear 2s forwards;
}
@keyframes coverShow {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
.coverDisappear{
    animation: coverDisappear 3s  forwards;
}
.coverShow{
    display: block;
    animation: coverShow 3s linear forwards;
    // display: block;
}
//    .coverPage_two_top{
//         // background-color: pink;
//         // top: 2900px;
//     //   position: fixed;
//       top: 0;
//         width: 100vw;
//         height: 300px;
//         z-index: 99;
//         background-color: red;
//         background-image: url('../../../assets/image/question/cover.png');
//         opacity: .76;
//         background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.6) 0.01%, rgba(0, 0, 0, 0) 100%);
//     }
.two_question{
    width: 100vw;
    height:100vh;
    position: fixed;
    top: 1770px;
    z-index: 0;
    // margin-top: 1170px;
    
    .coverPage_two{
        position: fixed;
        top: 1570px;
        // left: 0;
        width: 100vw;
        height:2000px;
        z-index: -14;
        background-image: url('../../../assets/image/question/zhegaiceng.png');
        opacity: .8;

    }
   
    // .coverPage_two_top{
    //     // background-color: pink;
    //     // top: 2900px;
      
    //     width: 100vw;
    //     height: 300px;
    //     z-index: 99;
    //     background-image: url('../../../assets/image/question/cover.png');
    //     opacity: .76;
    //     background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.6) 0.01%, rgba(0, 0, 0, 0) 100%);
    // }
    .two_question_Wizard {
        width: 300px;
        height: 521px;
        .Wizard{
            position: absolute;
            width: 300px;
            // background-color: pink;
            height: 521px;
            bottom: 95px;
            z-index: -89;
        }
        .smallBall{
            position: absolute;
         //    top: 1950px;
         display: block;
              bottom: 390px;
            left: 50%;
            transform: translateX(-30%);
            width: 64px;
            height: 96px;
            color: #FFFFFF;
            // background-color: aquamarine;s
            z-index: 6;
            
        }
    }
    .two_question_Wizard_square{
        #square1{
            position: absolute;
            bottom: 452px;
            left: 143px;
            width: 5px;
            height: 5px;
            z-index: -40;
        }
        #square2{
            position: absolute;
            bottom: 370px;
            left: 166px;
            width: 6px;
            height: 7px;
            z-index: -40;
        }
        #square3{
            position: absolute;
            bottom: 348px;
            left: 155px;
            width: 6px;
            height: 13px;
            z-index: -40;
        }
        #square4{
            position: absolute;
            bottom: 340px;
            left: 60px;
            width: 5px;
            height: 6px;
            z-index: -40;
        }
        #square5{
            position: absolute;
            bottom: 269px;
            left: 153px;
            width: 5px;
            height: 6px;
            z-index: -40;
        }
        .two_question_firefly1 {
            img{
                width: 80px;
                height: 80px;
                top: 520px;
                left: 40px;
                position: absolute;
                transform: rotate(10deg);
            }
        }   
        .two_question_firefly2 {
            img{
                width: 80px;
                height: 160px;
                top: 420px;
                left: 30px;
                position: absolute;
                transform: rotate(9deg);
            }
        }   
        .two_question_firefly3{
            img{
                width: 80px;
                height: 80px;
                top: 450px;
                left: 50px;
                position: absolute;
                transform: rotate(9deg);
            }
        }   
    }
   .two_question_title{
       position: absolute;
       width: 320px;
       height: 52px;
       bottom: 580px;
       left: 37px;
       font-family: 'YouSheBiaoTiHei';
       font-style: normal;
       font-weight: 400;
       font-size: 26px;
       color: #D0FDF8;
        text-shadow: 0px 0px 10px rgba(56, 255, 255, 0.6);
       box-sizing: border-box;
       text-align: left;
       z-index: 56;
       .two_question_title_subject{
            // display: none;
            display: flex;
            align-items: baseline;
            // justify-content: first baseline;
            // display: none;
            // background-color: pink;
            text-align: left;
            .subject{
                width: 300px;
                text-align: left;
            }
            img{
                margin-right: 5px;
            }
       }
   }
//     .smallBall{
//        position: absolute;
//     //    top: 1950px;
//          bottom: 390px;
//        left: 175px;
//        width: 64px;
//        height: 96px;
//        color: #FFFFFF;
//        z-index: 6;
       
//    }
   .two_question_ball img{
    position: absolute;
    bottom:-30px;
    left: 80px;
    width: 206px;
    height: 310px;
    color: #FFFFFF;
    display: none;
    }
    .two_question_options{
        position: absolute;
        width: 335px;
        height: 200px;
        bottom: 274px;
        left: 20px;
        z-index: 7;
        // margin-bottom: 10px;
        .two_question_config{
            width: 335px;
            height: 50px;
            font-family: 'YouSheBiaoTiHei';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 80px;
            color:  #E0FEFC;
            text-shadow: 0px 0px 10px rgba(56, 255, 255, 0.6);  
            box-sizing: border-box;
            span{
                margin-left: 30px;
            }
        }
        .two_question_one{
            margin-top: 30px;
            @extend  .two_question_config;
        }
        .two_question_two{
            @extend  .two_question_config;
            margin-top: 20px
        }
        .two_question_three{
            @extend  .two_question_config;
            margin-top: 20px;
        }
        .two_question_four{
            @extend  .two_question_config;
            margin-top: 20px;
        }
    }
    .two_previous_question{
        position: absolute;
        bottom: 50px;
        left: 30px;
        width: 56px;
        height: 26px;
        font-family: 'YouSheBiaoTiHei';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        color: #A1E0DC;
        display: block;
        z-index: 8;
    }


    //第二个
      .two_question_Wizard_copy {
        width: 300px;
        height: 521px;
        .Wizard_copy{
            position: absolute;
            width: 300px;
            height: 521px;
            bottom: 155px;
            z-index: -114514;
            }
        .smallBall_copy{
            position: absolute;
            //    top: 1950px;
                 bottom: 450px;
               left: 50%;
               transform: translateX(-30%);
               width: 64px;
               height: 96px;
               color: #FFFFFF;
            //    background-color: aquamarine;
               z-index: 6;
        }
    }
    .two_question_Wizard_square_copy{
        #square1{
            position: absolute;
            bottom: 452px;
            left: 143px;
            width: 5px;
            height: 5px;
            z-index: -40;
        }
        #square2{
            position: absolute;
            bottom: 370px;
            left: 166px;
            width: 6px;
            height: 7px;
            z-index: -40;
        }
        #square3{
            position: absolute;
            bottom: 348px;
            left: 155px;
            width: 6px;
            height: 13px;
            z-index: -40;
        }
        #square4{
            position: absolute;
            bottom: 340px;
            left: 60px;
            width: 5px;
            height: 6px;
            z-index: -40;
        }
        #square5{
            position: absolute;
            bottom: 269px;
            left: 153px;
            width: 5px;
            height: 6px;
            z-index: -40;
        }
        .two_question_firefly1 {
            img{
                width: 80px;
                height: 80px;
                top: 520px;
                left: 40px;
                position: absolute;
                transform: rotate(10deg);
            }
        }   
        .two_question_firefly2 {
            img{
                width: 80px;
                height: 160px;
                top: 420px;
                left: 30px;
                position: absolute;
                transform: rotate(9deg);
            }
        }   
        .two_question_firefly3{
            img{
                width: 80px;
                height: 80px;
                top: 450px;
                left: 50px;
                position: absolute;
                transform: rotate(9deg);
            }
        }   
    }
    .two_question_title_copy{
        position: absolute;
        width: 299px;
        height: 52px;
        bottom: 610px;
        left: 37px;
        font-family: 'YouSheBiaoTiHei';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: #FFFFFF;
        text-shadow: 0px 0px 10px rgba(56, 255, 255, 0.6);  
        box-sizing: border-box;
        text-align: center;
        // display: flex;
        // align-items: baseline;
        // justify-content: center;
        .two_question_title_subject{
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            display: none;
            img{
                margin-right: 5px;
            }
        }
    }
    .two_question_ball_copy .smallBall{
        position: fixed;
        top: 1950px;
        left: 175px;
        width: 64px;
        height: 96px;
        color: #FFFFFF;
        z-index: 6;
        
    }
    .two_question_ball_copy img{
     position: absolute;
     bottom:20px;
     left: 80px;
     width: 206px;
     height: 310px;
     color: #FFFFFF;
     display: none;
     }
     .two_question_options_copy{
        position: absolute;
        width: 335px;
        height: 200px;
        bottom: 324px;
        left: 20px;
        z-index: 7;
        .two_question_config{
            width: 335px;
            height: 50px;
            font-family: 'YouSheBiaoTiHei';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 80px;
            color:  #E0FEFC;
            text-shadow: 0px 0px 10px rgba(56, 255, 255, 0.6);  
            box-sizing: border-box;
            span{
                margin-left: 30px;
            }
        }
        .two_question_one{
            margin-top: 30px;
            @extend  .two_question_config;
        }
        .two_question_two{
            @extend  .two_question_config;
            margin-top: 20px
        }
        .two_question_three{
            @extend  .two_question_config;
            margin-top: 20px;
        }
        .two_question_four{
            @extend  .two_question_config;
            margin-top: 20px;
        }
    }
    .two_previous_question_copy{
        position: absolute;
        bottom: 50px;
        left: 30px;
        width: 56px;
        height: 26px;
        font-family: 'YouSheBiaoTiHei';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        color: #A1E0DC;
        display: block;
        z-index: 8;
    }
}