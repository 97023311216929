@font-face{
    font-family:'YouSheBiaoTiHei';
    src:url(../../assets//fonts/YouSheBiaoTiHei-2.ttf)
}
@keyframes shakeY1 {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translate3d(0, -3px, 0);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translate3d(0, 3px, 0);
    }
}
@keyframes linedrop {
    from{
        transform: translateY(0);
    }
    to{
        transform: translateY(400px);
    }
}
#dynamic_grass{
    position: absolute;
    left: 50%;
    top:32.5%;
    transform: translateX(-44%);
    width:180px;
    height: 180px;
    background-size: cover;
}
.loadingOuter{
    .loading_empty{
        position: absolute;
        width:100%;
        height:100vh;
        z-index: 20;
    }
    width:100%;
    height:100vh;
    background-color: #040A20;
    position: absolute;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation-duration: 1s;
    .loading_container{
        width: 100%;
        height: 667px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .lineDrop{
            width:136px;
            height:550px;
            position:absolute;
            top:80px;
            z-index: 2;
            .line{
                width:1px;
                border-radius: 10;
                height:50px;
                margin-top: 100px;
                float: left;
                background-color: rgba(204, 249, 255, 0.8);
                opacity: 0.5;
            }
            #line1{
                position: relative;
                animation: linedrop  4s infinite ;
            }
            #line2{
                animation: linedrop 4s infinite 1s;
            }
            #line3{
                animation: linedrop 4s infinite 2.5s;
            }
        }
        .topMask{
            width:100%;
            height:210px;

            z-index: 3;
            background-color: #040A20;       
            //background-color: pink; 
        }
        .bottomMask{
            width:100%;
            height:290px;
            position: absolute;
            bottom:0px;
            z-index: 3;
            background-color: #040A20;
            //background-color: pink; 
        }
    
        .loadingLetter{
            position: relative;
            top: -200px;
            width: 200px;
            text-align: center;
            z-index: 10;
            .shakeY1 {
                animation-name: shakeY1;
                --animate-duration: 10s;
                animation-iteration-count:infinite;
            }
            img:nth-child(1){
                position: relative;
                top:220px;
                z-index: 1;
                width:105px;
                height:74px;
                background-size: cover;
            }
            img:nth-child(2){
                position: relative;
                top:110px;
                width:211px;
                height:205px;
                z-index: 3;
            }
            img:nth-child(3){
                position: relative;
                left:2px;
                width:190px;
                height:123px;
                background-size: cover;
                z-index: 3;
            }
            
        }
        .progressBar{
            z-index: 3;
            width: 60%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 20;
            position: relative;
            top:-200px;
            .ant-progress{
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .finish{
                color: rgba(198, 254, 255, 0.8);
                font-family: 'YouSheBiaoTiHei';
            }
        }
    }
}
// #loading_letter_img{
    
// }
#transport_finise{
    animation-iteration-count:2;
    --animate-duration: 0.5s;
}