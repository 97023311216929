.route {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
 

.fade-enter {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
}
 
.fade-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 1000ms ease-in;
}
 
.fade-exit {
    opacity: 1;
}
 
.fade-exit-active {
    opacity: 0;
    transition: opacity 1000ms ease-in;
}